import * as React from 'react'
import { PageOutDto } from '@iam/shared'
import config from '../config.json'
import * as styles from './app.module.css'

const isProduction = process.env.NODE_ENV === 'production'
const stylesheetHost = isProduction ? config.hostname.production : config.hostname.development

type TApp = {
  children: React.ReactNode;
  seo: PageOutDto['seo'];
}

export const App = ({ children, seo }: TApp) => {
  return (
    <html>
    <head>
      <meta charSet="utf-8"/>
      <meta name="viewport" content="width=device-width, initial-scale=1"/>

      <title>{seo.title}</title>
      {seo.description && <meta name="description" content={seo.description}/>}
      {seo.canonical && <link rel="canonical" href={seo.canonical}/>}

      <link rel="stylesheet" href={`${stylesheetHost}/main.css`}/>
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Noto%20Sans"/>

      <link rel="stylesheet" href="https://cdn.jsdelivr.net/gh/highlightjs/cdn-release@11.9.0/build/styles/atom-one-light.min.css"/>
      <script src="https://cdn.jsdelivr.net/gh/highlightjs/cdn-release@11.9.0/build/highlight.min.js"></script>

      <link rel="icon" type="image/x-icon" href="/favicon.ico"/>
      <link rel="apple-touch-icon" sizes="180x180" href="/static/favicon/apple-touch-icon.png"/>
      <link rel="icon" type="image/png" sizes="32x32" href="/static/favicon/favicon-32x32.png"/>
      <link rel="icon" type="image/png" sizes="16x16" href="/static/favicon/favicon-16x16.png"/>
      <link rel="manifest" href="/static/site.webmanifest"/>
    </head>

    <body className={styles.body}>
    {children}
    </body>
    </html>
  )
}
