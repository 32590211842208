import { useEffect } from 'react'
import { PageOutDto } from '@iam/shared'

export const useSeo = (seo?: PageOutDto['seo']) => {
  useEffect(() => {
    if (seo?.title) {
      document.title = seo.title
    }

    return () => {
      document.title = ''
    }
  }, [])
}
