import * as React from 'react'
import { Page } from '../components/page/page.tsx'
import { API_HOST } from '../constants.ts'
import { Heading } from '../components/heading/heading.tsx'
import { useLoaderData } from 'react-router-dom'

export const loader = async () => {
  const nav = await fetch(`${API_HOST}/nav`)
  const navJson = await nav.json()

  return {
    ...navJson,
  }
}

export const NotFound = () => {
  const { page } = useLoaderData() as any

  return (
    <Page>
      <Heading>{page.title}</Heading>
    </Page>
  )
}
