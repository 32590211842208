import * as React from 'react'
import { HeadingTypes } from './heading.types.ts'
import * as styles from './heading.module.css'

type THeadingProps = {
  children: React.ReactNode;
  className?: string;
  type?: HeadingTypes;
}

export const Heading = ({ type = 'h1', children, className }: THeadingProps) => {
  const Element = React.createElement(
    type,
    {
      className: `${styles.heading} ${className} ${styles[type]}`,
    },
    children
  )

  return Element
}
