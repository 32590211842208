import * as React from 'react'

type SyntaxHighlightedCodeProps = {
  className: string;
  children: React.ReactNode;
}

export const SyntaxHighlightedCode = (props: SyntaxHighlightedCodeProps) => {
  const ref = React.useRef<HTMLElement | null>(null)

  React.useEffect(() => {
    // @ts-ignore
    if (ref.current && props.className?.includes('lang-') && window.hljs) {
      // @ts-ignore
      window.hljs.highlightElement(ref.current)

      // hljs won't reprocess the element unless this attribute is removed
      ref.current.removeAttribute('data-highlighted')
    }
  }, [props.className, props.children])

  return <code {...props} ref={ref} />
}
