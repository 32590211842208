import { PageOutDto, Routes, utils } from '@iam/shared'

export const parseHydrationData = (): unknown | void => {
  try {
    // @ts-ignore
    return utils.parseLoaderData(window.__staticRouterHydrationData ?? {})
  } catch (e) {
    console.error(e)
  }
}

export const parseRoutes = (): Routes => {
  const data = parseHydrationData()

  if (!data || typeof data !== 'object') {
    return {}
  }

  return ('routes' in data ? data.routes : {}) as Routes
}

export const parsePage = (): PageOutDto => {
  const data = parseHydrationData()

  if (!data || typeof data !== 'object') {
    return {} as PageOutDto
  }

  return ('page' in data ? data.page : {}) as PageOutDto
}

export const parseSeo = (): PageOutDto['seo'] => {
  const page = parsePage()

  return page.seo
}
