import { LoadNotFound, LoadPages } from '../ports.output.ts'
import { GetRoutes } from '../ports.input.ts'
import { RoutesVisitor } from '../domain/routesVisitor.ts'

type Dependencies = {
  loadPages: LoadPages,
  loadNotFound: LoadNotFound,
}

export const createGetRoutes = ({
  loadPages,
  loadNotFound,
}: Dependencies): GetRoutes => async () => {
  try {
    const pages = await loadPages()
    const notFound = await loadNotFound()

    const routesVisitor = new RoutesVisitor(notFound)
    pages.accept(routesVisitor)

    return routesVisitor.getRoutes()
  } catch (e) {
    console.error(e)
  }
}
