import * as React from 'react';
import { Page } from '../components/page/page.tsx';
import { Heading } from '../components/heading/heading.tsx';
import { API_HOST } from '../constants.ts'

export const loader = async () => {
  const nav = await fetch(`${API_HOST}/nav`)
  const navJson = await nav.json()

  return {
    ...navJson,
  }
}

export const Portfolio = () => {
  return (
    <Page>
      <Heading>Портфолио</Heading>
      ...
    </Page>
  )
}
