import { StaticHandlerContext } from 'react-router-dom/server.js'
import { RouteHandler, Routes, RoutesByType } from './routes-types.ts'

const notFoundRoute = () => 'Not found'

const routesMatch = (routes: Routes): RoutesByType => {
  const dynamicSymbol = '*'

  const result: RoutesByType = {
    staticRoutes: {},
    dynamicRoutes: []
  }

  return Object.entries(routes).reduce((acc: RoutesByType, [route, handler]: [string, RouteHandler]) => {
    if (route.includes(dynamicSymbol)) {
      const routePrepared = route.split(dynamicSymbol).join('(.*)')
      const routeRegExp = new RegExp(routePrepared)

      acc.dynamicRoutes.push([routeRegExp, handler])
    } else {
      acc.staticRoutes[route] = handler
    }

    return acc
  }, result)
}

export const createGetRoute = (routes: Routes) => {
  const { staticRoutes, dynamicRoutes } = routesMatch(routes)

  return (url?: string): [RouteHandler, RegExpMatchArray?] => {
    const staticRoute = staticRoutes[url!]
    if (staticRoute) {
      return [staticRoute]
    }

    for (const [reqExp, route] of dynamicRoutes) {
      const rx = url!.match(reqExp)
      if (rx) {
        return [route, rx]
      }
    }

    return [notFoundRoute]
  }
}

export const parseLoaderData = (data: StaticHandlerContext): unknown => {
  // Данные хранятся в аттрибуте для каждого лоадера.
  return data.loaderData && Object.values(data.loaderData).at(0)
}
