import * as React from 'react'
import { Page } from '../components/page/page.tsx'
import { API_HOST } from '../constants.ts'
import { Heading } from '../components/heading/heading.tsx'

export const loader = async () => {
  const nav = await fetch(`${API_HOST}/nav`)
  const navJson = await nav.json()

  const page = await fetch(`${API_HOST}/pages/main`)
  const pageJson = await page.json()

  return {
    ...navJson,
    ...pageJson,
  }
}

export const Main = () => {
  return (
    <Page>
      <Heading>Обо мне</Heading>

      {/*Вводная часть*/}
      {/*Приветствие*/}
      <p>Доброго времени суток!</p>

      {/*Представление*/}
      <p>
        Меня зовут Александр. Работаю JavaScript-программистом. Здесь я пишу о веб-разработке и делюсь своим опытом.
        Ниже более подробно обо мне.
      </p>

      <p>
        Мне 36 лет. Живу в городе Санкт-Петербург. С детства нравилось создавать что-то полезное.
        Так, я оказался в университете ИТМО.
      </p>

      {/*Основная часть*/}
      {/*Описать опыт работы*/}
      <p>Занимаюсь веб-разработкой 12 лет.</p>

      {/*html/css. верстка*/}
      <p>Началось с верстки сайтов на HTML/CSS. Получил год практики в одной веб-студии.</p>

      <p>После окончания университета попробовал себя в роли JS-программиста.</p>

      {/*высоконагруженный js в браузере*/}
      <p>
        Знакомство с JS началось в известной компании СПб. Здесь получил опыт работы с ванильным JS.
        Мои JS-скрипты выполнялись на известных и высоконагруженных интернет площадок.
      </p>

      {/*разработка SPA*/}
      <p>
        Далее создавал веб-приложения (SPA), богатые клиентской логикой: графики со статистикой,
        работа с различными структурами данных и сложные формы ввода.
      </p>

      <p>
        Приобрел опыт работы в объектно-ориентированном и функциональном парадигмах программирования.
        Работал в сферах интернет-маркетинга, ритейла и финтеха.
      </p>

      {/*разработка Universal App, полный стэк*/}
      <p>
        Сейчас занимаюсь разработкой изоморфных JS-приложений: удобных в поддержке и развитии, легко индексируемые
        поисковиками,
        быстрыми и удобными для пользователей.
      </p>

      {/*Статьи*/}
      {/*Иногда пишу статьи*/}

      {/*### Описать мой стэк*/}
      {/*#### js*/}
      {/*#### SPA*/}
      {/*#### ts*/}
      {/*#### nodejs*/}
      {/*Более подробно про мой стэк можно узнать здесь.*/}

      {/*### Мои софт-скилы*/}

      {/*## Заключение*/}
    </Page>
  )
}
