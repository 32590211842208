import * as React from 'react'
import { RouteObject } from 'react-router-dom'
import { Routes } from '@iam/shared'
import * as Pages from './pages/index.ts'

// type TPageMap = {
//   [Property in keyof typeof Pages]: React.FunctionComponent;
// }

export const createRoutes = (routes: Routes): RouteObject[] => {
  return Object.entries(routes).map(([uri, name]): RouteObject => {
    const PageMap = Pages[name as keyof typeof Pages]
    // @ts-ignore
    const Page = PageMap[name as keyof typeof PageMap] as React.FunctionComponent
    // @ts-ignore
    const loader = PageMap.loader as RouteObject['loader']

    return {
      loader,
      path: uri,
      element: <Page /> ?? 'Page not found',
    }
  })
}
