import * as React from 'react'
import * as styles from './blockquote.module.css'

type LinkProps = {
  children: React.ReactNode;
}

export const Blockquote = ({ children, ...props }: LinkProps) => {
  return (
    <blockquote {...props} className={styles.blockquote}>{children}</blockquote>
  )
}
