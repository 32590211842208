import * as React from 'react'
import { useSeo } from '../../hooks/index.ts'
import { Footer } from '../footer/footer.tsx'
import { Header } from '../header/header.tsx'
import * as styles from './page.module.css'
import { useLoaderData } from 'react-router-dom'

type TPage = {
  children: React.ReactNode
}

export const Page = ({ children }: TPage) => {
  const { page } = useLoaderData() as any

  useSeo(page?.seo)

  return (
    <div className={styles.page}>
      <Header />

      <main className={styles.main}>
        {children}
      </main>

      <Footer />
    </div>
  )
}
