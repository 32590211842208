import * as React from 'react';
import { useLoaderData } from 'react-router-dom'
import { PageOutDto } from '@iam/shared'
import { Link } from '../link/index.ts'
import * as styles from './nav.module.css';

export const Nav = () => {
  const { nav } = useLoaderData() as any

  return (
    <nav>
      <ul className={styles.items}>
        {nav.map(({ id, title, segment }: PageOutDto) => (
          <li key={id} className={styles.item}>
            <Link to={`/${segment}`}>{title}</Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}
