import * as React from 'react'
import { ExternalLink } from '../link/index.ts'
import * as styles from './footer.module.css'

export const Footer = () => (
  <footer className={styles.footer}>
    <p className={styles.copyright}>© Федотов Александр, 2024</p>
    <ul className={styles.contacts}>
      <li>
        <ExternalLink href="https://github.com/gaer87" target="_blank">GitHub</ExternalLink>
      </li>
    </ul>
  </footer>
)
