import * as React from 'react'
import * as styles from './link.module.css'

type LinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  children: React.ReactNode;
}

export const ExternalLink = ({ children, ...props }: LinkProps) => {
  return (
    <a {...props} className={styles.link}>{children}</a>
  )
}
