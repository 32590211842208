import * as React from 'react'
import { useLoaderData } from 'react-router-dom'
import * as domain from '@iam/shared'
import { API_HOST } from '../../constants.ts'
import { Page } from '../../components/page/page.tsx'
import { Link } from '../../components/link/index.ts'
import { Heading } from '../../components/heading/heading.tsx'
import * as styles from './posts.module.css'

export const loader = async () => {
  const nav = await fetch(`${API_HOST}/nav`)
  const navJson = await nav.json()

  const posts = await fetch(`${API_HOST}/posts`)
  const postsJson = await posts.json()

  const page = await fetch(`${API_HOST}/pages/posts`)
  const pageJson = await page.json()

  return {
    ...navJson,
    ...postsJson,
    ...pageJson,
  }
}

export const Posts = () => {
  const { posts } = useLoaderData() as any

  return (
    <Page>
      <Heading>Статьи</Heading>

      {posts.map(({ id, title, url }: domain.PageOutDto) => (
        <article key={id}>
          <Heading type="h2" className={styles.heading}>
            <Link to={`/${url}`}>{title}</Link>
          </Heading>
        </article>
      ))}
    </Page>
  )
}
