import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { App } from './components/app.tsx'
import { createRoutes } from './routes.tsx'
import { parseRoutes, parseSeo } from './utils/index.js'

const seo = parseSeo()
const routesMap = parseRoutes()
const routes = createRoutes(routesMap)
const router = createBrowserRouter(routes)

ReactDOM.hydrateRoot(
  document,
  <App seo={seo}>
    <RouterProvider router={router}/>
  </App>
)
