import * as React from 'react'
import { LoaderFunction, useLoaderData } from 'react-router-dom'
import Markdown from 'markdown-to-jsx'
import { Page } from '../../components/page/page.tsx'
import { Heading } from '../../components/heading/heading.tsx'
import { API_HOST } from '../../constants.ts'
import { Blockquote } from '../../components/blockquote/index.ts'
import { ExternalLink } from '../../components/link/index.ts'
import { SyntaxHighlightedCode } from '../../components/syntax-highlighted-code/index.tsx'
import * as styles from './post.module.css'

export const loader: LoaderFunction = async ({ params }) => {
  const nav = await fetch(`${API_HOST}/nav`)
  const navJson = await nav.json()

  if (!params.id) {
    console.error('Не удалось найти Id.')
    return {
      ...navJson,
    }
  }

  const post = await fetch(`${API_HOST}/posts/${params.id}`)
  const postJson = await post.json()

  const page = await fetch(`${API_HOST}/pages/${params.id}`)
  const pageJson = await page.json()

  return {
    ...navJson,
    ...postJson,
    ...pageJson,
  }
}

export const Post = () => {
  const { post } = useLoaderData() as any

  if (!post) {
    return (
      <Page>
        <Heading>Not found</Heading>
      </Page>
    )
  }

  return (
    <Page>
      <article>
        <header className={styles.header}>
          <Heading className={styles.heading}>{post.title}</Heading>
          <time className={styles.time}>{new Date(post.created).toLocaleDateString('ru')}</time>
        </header>

        <Markdown options={{
          wrapper: 'section',
          overrides: {
            a: {
              component: ExternalLink,
              props: {
                target: '_blank',
              },
            },
            code: SyntaxHighlightedCode,
            blockquote: Blockquote,
          },
        }}>{post.content}</Markdown>
      </article>
    </Page>
  )
}
